<template>
    <div>
        <v-card flat v-if="Report.Entries.length > 0">
           <v-card-title class="secondary white--text d-flex justify-space-between">
            <TableSearch @input="(newText) => {search = newText}" />
            <p class="subtitle-1 my-0">Total - {{ toCurrencyFilter(Report.Total, currencyData) }}</p>
          </v-card-title>
          <v-data-table
            class="tableColor"
            v-if="Report.Entries.length > 0"
            :loading="loading"
            :headers="headers"
            :items="Report.Entries"
            :items-per-page="10"
            :search="search"
          >

            <template v-slot:item.RentDueDate="{ item }">
              <span>
                {{ item.RentDueDate | formatDate }}
              </span>
            </template>
            <template v-slot:item.RentDueAmount="{ item }">
              <span>
                {{ toCurrencyFilter(item.RentDueAmount, currencyData) }}
              </span>
            </template>
            <template v-slot:item.ManagementFee="{ item }">
              <span>
                {{ toCurrencyFilter(item.ManagementFee, currencyData) }}
              </span>
            </template>
          </v-data-table>  
        </v-card>
    </div>
  </template>
  
  <script>
  import {mapGetters} from 'vuex'
  import {toCurrencyFilter} from '@/jsFunctions/toCurrencyFilterFunction.js'
  import TableSearch from '@/components/TableComponents/TableSearch.vue'
  export default {
    name: 'ForecastTable',
    components: {
        TableSearch
    },
    props:{
        Report: Object
    },
    data: () => ({
      headers: [
        { text: 'Branch Name', value: 'BranchName' },
        { text: 'Branch Code', value: 'BranchCode' },
        { text: 'Address', value: 'Address' },
        { text: 'Rent Due Date', value: 'RentDueDate' },
        { text: 'Rent Due Amount', value: 'RentDueAmount' },
        { text: 'Landlord Name', value: 'LandlordName' },
        { text: 'Management Fee', value: 'ManagementFee' },
        { text: 'Service Type', value: 'ServiceType' },
      ],
      search: '',
      loading: false
    }),
    methods: {
        toCurrencyFilter
    },
    computed: {
      ...mapGetters({
        currencyData: "CurrencyData/currencyInfo"
      }),
      computedDateFormattedMomentjs () {
        return this.date ? moment(this.date).format('MMMM YYYY') : ''
      },
    }
  }
  </script>