// Generic method for converting numeric values to currency values:-
export function toCurrencyFilter(value, currencyData){
    if (typeof value !== "number") {
        return value;
    }
    if(currencyData != undefined){
    var formatter = new Intl.NumberFormat(currencyData.LocaleCode, {
        style: 'currency',
        currency: currencyData.CurrencyCode,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    return formatter.format(value);
    }
    else{
      return null;
    }
}
