<template>
  <v-container class="py-0">
    <transition name="fade" mode="out-in" appear>
      <PageTitle class="d-block d-sm-none">Forecasts</PageTitle>
    </transition>
    <transition name="slide-left-fade" mode="out-in" appear>
      <v-tabs
        v-model="tab"
        class="mt-5 mt-sm-1"
        color="tabColor"
        slider-color="tabColor"
        center-active
        background-color="tertiary"
        show-arrows
        height="55"
      >
        <v-tab v-for="report in forecastReports" :key="report.ReportTitle" ripple>
          {{ report.ReportTitle }} 
        </v-tab>
        <v-spacer></v-spacer>
        <span class="d-none d-sm-block">
          <PageTitle>Forecasts</PageTitle>
        </span>
      </v-tabs>
    </transition>

     <transition name="slide-fade" mode="out-in" appear>
      <div>
        <v-tabs-items v-model="tab" class="pt-2 tertiary">
          <v-tab-item v-for="report in forecastReports" :key="report.ReportTitle">
            <ForecastTable :Report="report" />
            <Skeleton v-if="!report.Entries.length > 0"/>
          </v-tab-item> 
        </v-tabs-items>
      </div>
    </transition>
  </v-container>
</template>
<script>
import {mapGetters} from 'vuex'
//import Skeleton from '@/components/SkeletonLoaders/SkeletonLoaderTable.vue'
import PageTitle from '@/components/Slots/PageTitle.vue'
import ForecastTable from '../components/Forecasts/ForecastTable.vue'

export default {
  components: {
    //Skeleton,
    PageTitle,
    ForecastTable
},
  created() {
    this.$store.dispatch("ManagementFeeForecast/getManagementFeeForecasts")
  },
  data() {
    return {
      tab: null
    }
  },
  computed: {
    ...mapGetters({
      forecastReports: "ManagementFeeForecast/reports"
    })
  }
}
</script>

<style>

</style>