<template>
  <div>
    <v-text-field
      dark
      outlined
      dense
      v-model="value"
      append-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
      class="mt-5 mt-sm-0"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: 'tableSearchField',
  data() {
    return {
      value: ''
    }
  },
  watch: {
    value() {
      this.$emit('input', this.value);
    }
  }
}
</script>

<style>

</style>