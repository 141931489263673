<template>
  <div class="mt-3 mb-n5 d-flex flex-sm-column align-center align-sm-end justify-sm-start justify-space-between">
    <span class="font-weight-regular grey--text text-capitalize text-body-1 text-body-h6">
      <slot></slot>
    </span>
    <span class="d-sm-block body-2 font-weight-light grey--text text-capitalize">{{ this.$store.state.branchName }}</span>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>